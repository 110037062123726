import { Link } from 'gatsby';
import React from 'react';
import styled from "styled-components";


const NabidkaRakviInner = styled.div`
  .galerie {
    background: #F3F1EC;
    padding: 60px 0;
  
    @media(max-width: 800px) {
      padding: 35px 0;
    }
  }
  
  .galerieInner {
    max-width: 1150px;
    margin: 0 auto;
    width: calc(100% - 100px);

    @media(max-width: 899px) {
      width: calc(100% - 50px);
    }

    @media(max-width: 800px) {
      max-width: 400px;
    }
  }

  .galerieInnerGrid {
    display: grid; 
    grid-template-columns: 350px 350px 350px; 
    gap: 50px calc((100% - 350px*3)/2); 
    width: calc(100%);
    height: 100%;

    @media(max-width: 1210px) {
      grid-template-columns: 48% 48%; 
      gap: 50px calc((4%)); 
    }

    @media(max-width: 800px) {
      grid-template-columns: 100%; 
      gap: 30px 0;
    }

    .rakev {
      background: #FFFFFF;
      box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
      border-radius: 10px;
      overflow: hidden;
      height: fit-content;

      .rakevInner {
        width: calc(100% - 40px);
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;

      }

      img {
        object-fit: cover;
        height: 190px;
        width: 100%;
        display: block;
      }

      h3 {
        font-family: Visuelt-Bold;
        font-size: 18px;
        color: #000000;
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 14px;
        color: #787878;
        line-height: 20px;
        margin-bottom: 15px;
        margin-top: 10px;
      }
      
      .bot {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          .cena {
            font-family: Visuelt-Medium;
            font-size: 16px;
            color: #000000;
            line-height: 20px;
            display: block;
            margin-bottom: 5px;
          }

          .region {
            display: block;
            font-family: Visuelt-Regular;
            font-size: 14px;
            color: #656565;
            line-height: 20px;

            a {
              color: #656565;
            }
          }
        }
      }

      .koupit {
        font-family: Visuelt-Medium;
        font-size: 18px;
        color: #FFFFFF;
        background: #3A557C;
        border-radius: 5px;
        text-decoration: none;
        padding: 11px 15px;
        display: block;

        &:hover {
          background: #243A58;
        }
      }
    }
  }
`;

const NabidkaRakvi = ({rakve, type}) => {





  return (
    <NabidkaRakviInner>

      <div className='galerie'>
        <div className='galerieInner'>
          <div className='galerieInnerGrid'>
            {rakve.map(rr => {
              const r = rr.node;

              let muzeZobrazit = false;



              let region;

              if (typeof r.regions[0] === "undefined" || typeof r.regions[0].name === "undefined") {
                region = <Link to="/pohrebni-sluzba/">Pohřební služba</Link>;
              }

              else {
                switch (r.regions[0].name) {
                  case "Praha a okolí": 
                    region = <Link to="/pohrebni-sluzba-praha/">Praha a okolí</Link>;
                    break;
  
                  case "Brno": 
                    region = <Link to="/pohrebni-sluzba-brno/">Brno</Link>;
                    break;
  
                  case "Ostrava": 
                    region = <Link to="/pohrebni-sluzba-ostrava/">Ostrava</Link>;
                    break;
  
                  case "Havířov": 
                    region = <Link to="/pohrebni-sluzba-havirov/">Havířov</Link>;
                    break;

                  case "Liberec": 
                    region = <Link to="/pohrebni-sluzba-liberec/">Liberec</Link>;
                    break;
                  
                  case "Ústí nad Labem": 
                    region = <Link to="/pohrebni-sluzba-usti-nad-labem/">Ústí nad Labem</Link>;
                    break;
  
                  case "Pardubice": 
                    region = <Link to="/pohrebni-sluzba-pardubice/">Pardubice</Link>;
                    break;
  
                  case "Jihlava": 
                    region = <Link to="/pohrebni-sluzba-jihlava/">Jihlava</Link>;
                    break;
  
                  case "Kladno": 
                    region = <Link to="/pohrebni-sluzba-kladno/">Kladno</Link>;
                    break;
  
                  case "Uherské Hradiště": 
                    region = <Link to="/pohrebni-sluzba-uherske-hradiste/">Uherské Hradiště</Link>;
                    break;
  
                  case "Plzeň": 
                    region = <Link to="/pohrebni-sluzba-plzen/">Plzeň</Link>;
                    break;     
                    
                  case "Písek": 
                    region = <Link to="/pohrebni-sluzba-pisek/">Písek</Link>;
                    break;
                    
                  case "České Budějovice": 
                    region = <Link to="/pohrebni-sluzba-ceske-budejovice/">České Budějovice</Link>;
                    break;  
                  
                  case "Jablonec nad Nisou": 
                    region = <Link to="/pohrebni-sluzba-jablonec-nad-nisou/">Jablonec nad Nisou</Link>;
                    break; 
  
                  case "Opava": 
                    region = <Link to="/pohrebni-sluzba-opava/">Opava</Link>;
                    break;
  
                  case "Kolín": 
                    region = <Link to="/pohrebni-sluzba-kolin/">Kolín</Link>;
                    break;
  
                  case "Hradec Králové": 
                    region = <Link to="/pohrebni-sluzba-hradec-kralove/">Hradec Králové</Link>;
                    break;
  
                  case "Říčany": 
                    region = <Link to="/pohrebni-sluzba-ricany/">Říčany</Link>;
                    break;
  
                  case "Sázava": 
                    region = <Link to="/pohrebni-sluzba-sazava/">Sázava</Link>;
                    break;
  
                  case "Český Krumlov": 
                    region = <Link to="/pohrebni-sluzba-cesky-krumlov/">Český Krumlov</Link>;
                    break;
                
                  case "Kutná Hora": 
                    region = <Link to="/pohrebni-sluzba-kutna-hora/">Kutná Hora</Link>;
                    break;
  
                  case "Most": 
                    region = <Link to="/pohrebni-sluzba-most/">Most</Link>;
                    break;
  
                  case "Teplice":
                    region = <Link to="/pohrebni-sluzba-teplice/">Teplice</Link>;
                    break;
  
                  case "Děčín":
                    region = <Link to="/pohrebni-sluzba-decin/">Děčín</Link>;
                    break;
  
                  case "Chomutov": 
                    region = <Link to="/pohrebni-sluzba-chomutov/">Chomutov</Link>;
                    break;
  
                  case "Litoměřice": 
                    region = <Link to="/pohrebni-sluzba-litomerice/">Litoměřice</Link>;
                    break;
  
                  case "Poděbrady": 
                    region = <Link to="/pohrebni-sluzba-podebrady/">Poděbrady</Link>;
                    break;
  
                  case "Čáslav": 
                    region = <Link to="/pohrebni-sluzba-caslav/">Čáslav</Link>;
                    break;
  
                  default:
                    region = r.regions[0].name;
                    break;
                }
              }

              














              if (type === "vsechny") {
                muzeZobrazit = true;
              }

              else {
                if (r.rakveKategorie) {
                  let ruzneKategorie = r.rakveKategorie.split(", ");

                  ruzneKategorie.forEach(element => {
                    if (element === type) {
                      muzeZobrazit = true;
                    }
                  });
                }
              }


              if (muzeZobrazit) {

                return(
                  <div key={r.id} className="rakev">
                    <img src={r.picture.url} alt={"Rakev "+r.name} />
                      <div className='rakevInner'>
                        <h3>{r.name}</h3>
                        <p>{r.description}</p>
                        <div className='bot'>
                          <div className='left'>
                            <span className='cena'>+{r.price.toLocaleString("fr-FR")} Kč (včetně DPH)</span>
                            <span className='region'>Region: {region}</span>
                          </div>
                          <Link className="koupit" to="/nabidka/dotaznik/">Koupit</Link>
                        </div>
                      </div>
                  </div>
                );
              }


            })}
          </div>
        </div>
      </div>
      

    
    </NabidkaRakviInner>
  );
}

export default NabidkaRakvi;
